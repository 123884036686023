// NotificationProvider.tsx
import {notification} from 'antd';
import React, {createContext, ReactNode, useContext} from 'react';

export type NotificationType = 'success' | 'info' | 'warning' | 'error';

interface NotificationContextProps {
    openNotificationWithIcon: (
        type: NotificationType,
        message: string,
        description?: string
    ) => void;
}

const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

export const NotificationProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [api, contextHolder] = notification.useNotification();

    const openNotificationWithIcon = (
        type: NotificationType,
        message: string,
        description = ''
    ) => {
        api[type]({
            message,
            description,
        });
    };

    return (
        <NotificationContext.Provider value={{openNotificationWithIcon}}>
            {contextHolder}
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    const context = useContext(NotificationContext);
    if (!context) {
        throw new Error('useNotification must be used within a NotificationProvider');
    }
    return context;
};
